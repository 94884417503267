<template>
  <div v-if="!loading">
    <img src="@/assets/images/HMM_HNVW.png" alt="HMM_HNVW" class="bg-img" />
    <nav>
      <div class="flex items-start">
        <img src="@/assets/images/hinavi-logo.png" alt="hinavi-logo" class="w-[120px]" />
        <span class="ml-3 fs-22">{{ $t('common.labels.eWMS') }}</span>
      </div>
    </nav>
    <div class="login-content sm:justify-center lg:justify-end">
      <div class="login-greeting-text hidden md:flex">
        <h1 class="font-black text-[60px]">HMM</h1>
        <h1 class="font-black text-[60px]">{{ $t('common.labels.integratedNavigate') }}</h1>
        <h1 class="font-black text-[60px]">{{ $t('common.labels.integratedWMS') }}</h1>
      </div>

      <el-form
        :model="form"
        class="login-dialog lg:mr-[12rem] w-[400px] px-5 pt-[30px]"
        @keyup.enter="debouncedOnSubmit"
      >
        <el-form-item>
          <div class="flex flex-col">
            <div class="flex justify-center items-start mb-5 gap-x-2">
              <span>{{ $t('common.labels.login') }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          {{ $t('common.labels.userID') }}
          <el-input
            v-model="form.username"
            size="large"
            :placeholder="$t('common.labels.userID')"
            :class="errorMessages.username ? 'error' : ''"
          />
        </el-form-item>
        <el-form-item>
          {{ $t('common.labels.userPassword') }}
          <el-input
            v-model="form.password"
            type="password"
            size="large"
            :placeholder="$t('common.labels.userPassword')"
            :class="{ error: showRecheck }"
            show-password
          />
          <div v-if="showRecheck" class="text-red-600">
            {{ $t('common.messages.recheckUsernamePassword') }}
          </div>
        </el-form-item>
        <el-form-item>
          <el-checkbox-group v-model="form.type">
            <el-checkbox v-model="form.type" :label="$t('common.labels.staySignedIn')" />
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="large" class="w-full" @click="debouncedOnSubmit">{{
            $t('common.labels.login')
          }}</el-button>
        </el-form-item>

        <el-form-item class="float-left mb-5 mt-5">
          <el-button
            type="primary"
            plain
            size="large"
            class="btn-transparent px-0"
            @click="handleChangePassword"
          >
            <div class="flex items-center">
              <lockIcon class="min-w-[17px] min-h-[17px] mr-2" />
              <div class="text-[15px] font-bold">{{ $t('common.titles.changePassword') }}</div>
            </div></el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <ChangePasswordPopup :visible="showChangePassword" @close="showChangePassword = false" />
  </div>
  <Preloader v-else></Preloader>
</template>

<script setup lang="ts">
import { onMounted, reactive, ref, shallowRef } from 'vue'
import { ElNotification } from 'element-plus'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { CACHE, getEnv, setCache } from 'shared'
import { useRoute, useRouter } from 'vue-router'

import HeroNewIcon from '@/components/icons/HeroNewIcon.vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import { globalI18n } from '@/locales'
import { useAuthStore } from '@/stores/auth'
import { useCommonStore } from '@/stores/commons'
import { useMainStore } from '@/stores/main'
import { SSO_LOGIN_TYPE } from '@/types'

import ChangePasswordPopup from './ChangePasswordPopup.vue'
import Preloader from './Preloader.vue'

// SVGs
const lockIcon = shallowRef(LockIcon)

const loading = ref(true)
const showChangePassword = ref(false)
const router = useRouter()

const route = useRoute()

const showRecheck = ref(false)
interface Props {}

defineProps<Props>()

const handleChangePassword = () => {
  showChangePassword.value = true
}

const startPreloader = () => {
  setTimeout(() => {
    loading.value = false
  }, 3500)
}

onMounted(() => {
  const { path, query } = route
  if (path?.includes(SSO_LOGIN_TYPE.HOS)) {
    // HOS SSO login
    loginWithHosSSO(query?.token as string)
  } else if (path?.includes(SSO_LOGIN_TYPE.HMM)) {
    // HMM SSO login
    loginWithHmmSSO(query?.token as string)
  } else {
    // initialize login screen
    startPreloader()
  }
})

// do not use same name with ref
const form = reactive({
  username: '',
  password: '',
  type: [],
})

const errorMessages = ref({
  username: null,
  password: null,
})

const editIcon = ref(HeroNewIcon)

const onLoggedIn = async (userInfo) => {
  // mark logged = true
  useAuthStore().setLogin()
  // store the user information
  useAuthStore().setUser(userInfo)
  // initialize common data
  useCommonStore().initCommonData()
  // redirect to home
  router.push('/')
}

const notifyError = (errorMessage: string) => {
  console.error(errorMessage)
  ElNotification({
    title: globalI18n.t('common.messages.error'),
    message: errorMessage,
    type: 'error',
    position: 'top-left',
  })
}

// Login with HOS SSO
const loginWithHosSSO = async (hosSSOToken: string) => {
  try {
    if (!hosSSOToken || isEmpty(hosSSOToken)) {
      notifyError(globalI18n.t('message.invalidCredentials'))
    }
    loading.value = true
    // Login with HOS SSO TOKEN
    const response = await useAuthStore().loginWithHosSSO(hosSSOToken)
    if (response) {
      // on logged in event
      onLoggedIn(response)
    } else {
      notifyError(globalI18n.t('message.invalidCredentials'))
    }
  } catch (error) {
    console.error('loginWithHosSSO.error: ', error)
  } finally {
    loading.value = false
  }
}

// Login with HMM SSO
const loginWithHmmSSO = async (hmmSSOToken: string) => {
  try {
    if (!hmmSSOToken || isEmpty(hmmSSOToken)) {
      notifyError(globalI18n.t('message.invalidCredentials'))
    }
    loading.value = true
    // Login with HMM SSO TOKEN
    const response = await useAuthStore().loginWithHmmSSO(hmmSSOToken)
    if (response) {
      // on logged in event
      onLoggedIn(response)
    } else {
      notifyError(globalI18n.t('message.invalidCredentials'))
    }
  } catch (error) {
    console.error('loginWithHmmSSO.error: ', error)
  } finally {
    loading.value = false
  }
}
const requestHMMLogin = () => {
  const ssoLoginUrl = getEnv({ name: 'VITE_HMM_SSO_LOGIN_URL', defaultValue: '' })
  if (!isEmpty(ssoLoginUrl)) {
    window.location.href = ssoLoginUrl
  }
}
const debouncedRequestHMMLogin = debounce(requestHMMLogin, 300)

const onSubmit = async () => {
  try {
    // Reset error messages
    errorMessages.value.username = null
    errorMessages.value.password = null

    // Validate form inputs
    if (!form.username) {
      setError('username', 'message.recheckUsernamePassword')
      return
    }

    if (!form.password) {
      setError('password', 'message.recheckUsernamePassword')
      return
    }

    // Setting stay signed in
    setStaySignedIn(form.type)

    // Show loading indicator
    useMainStore().showLoading()

    try {
      // Attempt to login
      const response = await useAuthStore().loginWithUsernameAndPassword(
        form.username,
        form.password
      )
      if (response) {
        // Handle successful login
        onLoggedIn(response)
      }
    } catch (loginError) {
      console.error('Login failed:', loginError)
      showRecheck.value = true
    } finally {
      // Hide loading indicator
      useMainStore().hideLoading()
    }
  } catch (error) {
    // Handle unexpected errors
    useMainStore().hideLoading()
    console.error('Login error:', error)
  }
}

// Setting stay signed in
const setStaySignedIn = (val: string[] = []) => {
  setCache(CACHE.STAY_SIGNED_IN, val.length)
}

// Helper function to set error messages
const setError = (field, messageKey) => {
  errorMessages.value[field] = globalI18n.t(messageKey)
  showRecheck.value = true
}

const debouncedOnSubmit = debounce(onSubmit, 300)
</script>

<style lang="scss" scoped>
:deep(.el-button) {
  margin: 0;
  height: unset;
}
:deep(.custom-checkbox) {
  margin-left: 0px;
}
.login-container {
  height: 100vh;
  width: 100vw;
}

nav {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 4%;
  display: flex;
  align-items: center;
}

.bg-img {
  z-index: -1000;
  height: 100vh;
  width: 100vw;
  position: absolute;
  object-fit: fill;
  margin: 0;
}

.login-content {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
}

.login-dialog {
  background: white;
  margin-left: 12px;
  border-radius: 4px;
  opacity: 0.9;
  span {
    font-size: 18px;
  }
}
.login-dialog .form-title span {
  color: #555;
  font-size: 18px;
  letter-spacing: -0.5px;
}
.login-greeting-text {
  height: 400px;
  width: 400px;
  margin: 0 2rem;
  text-align: right;

  flex-direction: column;
  justify-content: center;
}
.login-greeting-text h1 {
  color: white;
}

.form-title {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0px;
}

.form-title h1 {
  color: #20265b;
  font-size: 25px;
  font-weight: 900;
}

.ultility-button-group :deep(.el-form-item__content) {
  display: flex;
  justify-content: space-between;
}
.ultility-button-group :deep(.el-button) {
  padding: 0px;
}

// fix input focus highlight behaviour
.el-form-item__content .el-input .el-input__wrapper.is-focus .el-input__inner:focus {
  outline: none;
  box-shadow: unset !important;
}

.login-width-btn button {
  font-weight: 700;
  border: 1px solid #20265b;
  color: #20265b;
}
.login-devider span {
  font-size: 0.9rem;
  display: flex;
  flex-basis: 100%;
}
.login-devider span:after {
  content: '';
  flex-grow: 1;
  height: 1px;
  line-height: 0;
  background: #c3c3c3;
  margin: 5px 16px;
}
.login-devider span:before {
  content: '';
  flex-grow: 1;
  height: 1px;
  line-height: 0;
  background: #c3c3c3;
  margin: 5px 16px;
}
.fs-22 {
  font-weight: 900;
  margin-top: 5px;
}
</style>
